import { makeAutoObservable } from "mobx";
import { ToastItem } from "../models/ToastItem";
import { RootStore } from "./RootStore";

export class ToastStore {
  toasts: ToastItem[] = [];

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this);
  }

  add(toast: Omit<ToastItem, "id">) {
    const id = Math.random().toString(36).substr(2, 9);
    this.toasts.push({ ...toast, id });

    if (toast.duration !== 0) {
      setTimeout(() => {
        this.remove(id);
      }, toast.duration || 5000);
    }
  }

  remove(id: string) {
    this.toasts = this.toasts.filter((n) => n.id !== id);
  }
}
