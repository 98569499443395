import { XMarkIcon } from "@heroicons/react/24/outline";
import { observer } from "mobx-react-lite";
import { NotificationType } from "../models/ToastItem";
import { rootStore } from "../stores/RootStore";

const getIcon = (type: NotificationType) => {
  switch (type) {
    case "success":
      return "✅";
    case "error":
      return "❌";
    case "warning":
      return "⚠️";
    case "info":
    default:
      return "ℹ️";
  }
};

// Individual Toast Component
const Toast = ({ type = "info", caption, message, onClose }: { type: NotificationType; caption?: string; message: string; onClose: () => void }) => {
  return (
    <div className="bg-base-100 border border-neutral rounded w-full max-w-sm p-4">
      <div className="flex w-full justify-between items-start gap-2">
        <div className="flex gap-2 items-start min-w-0 flex-1">
          <div className="shrink-0">{getIcon(type)}</div>
          <div className="min-w-0 w-full">
            {caption && <h3 className="font-bold mb-0.5 break-words">{caption}</h3>}
            <div className="text-sm whitespace-normal break-words w-full">{message}</div>
          </div>
        </div>
        <button onClick={onClose} className="btn btn-ghost btn-xs btn-circle shrink-0">
          <XMarkIcon className="h-4 w-4" />
        </button>
      </div>
    </div>
  );
};

// Toast Container using DaisyUI's toast class
export const ToastContainer = observer(() => {
  const { toastStore } = rootStore;
  return (
    <div className="toast toast-top toast-end">
      {toastStore.toasts.map((toast) => (
        <div key={toast.id}>
          <Toast {...toast} onClose={() => toastStore.remove(toast.id)} />
        </div>
      ))}
    </div>
  );
});

export default ToastContainer;
