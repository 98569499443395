import { DateTime } from "luxon";
import { observer } from "mobx-react-lite";
import { rootStore } from "../../stores/RootStore";
import { Link } from "react-router-dom";
import { useState, useRef, useEffect } from "react";
import { UserIcon, ArrowRightStartOnRectangleIcon } from "@heroicons/react/24/outline";

const SidebarUserControl = observer(() => {
  const [isOpen, setIsOpen] = useState(false);
  const { authStore } = rootStore;
  const user = authStore.user;
  const displayName = user?.firstname + " " + user?.lastname;
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative mx-4 mb-4" ref={containerRef}>
      {/* Dropdown Menu - Positioned above the control */}
      <div
        className={`
        absolute bottom-full left-0 w-full
        bg-base-100 transition-all duration-50 ease-in-out
        ${isOpen ? "opacity-100 transform translate-y-0" : "opacity-0 transform translate-y-2 pointer-events-none"}
      `}
      >
        <div className={`rounded-t ${isOpen ? "border border-b-0 border-neutral" : ""}`}>
          <ul className="menu">
            <li>
              <Link to="/profile" className="flex items-center gap-2 p-2 hover:bg-neutral rounded" onClick={() => setIsOpen(false)}>
                <UserIcon className="size-4" />
                <span>Profil bearbeiten</span>
              </Link>
            </li>
            <li>
              <button
                onClick={() => {
                  setIsOpen(false);
                  authStore.logout();
                }}
                className="flex items-center gap-2 p-2 w-full hover:bg-neutral rounded"
              >
                <ArrowRightStartOnRectangleIcon className="size-4" />
                <span>Ausloggen</span>
              </button>
            </li>
          </ul>
        </div>
      </div>

      {/* User Control Button */}
      <div
        className={`
          flex items-center p-4 cursor-pointer
          transition-colors duration-50
          ${isOpen ? "rounded-t-none rounded-b border border-t-0 border-neutral" : "rounded border border-transparent"}
        `}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="flex-grow min-w-0 select-none">
          <div className="text-sm truncate" title={displayName}>
            Hey, {displayName}
          </div>
          <div className="text-xs text-neutral-500 truncate" title={user?.email}>
            <span>
              {DateTime.now().setLocale("de").toLocaleString({
                weekday: "long",
                day: "numeric",
                month: "long",
                year: "numeric",
              })}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
});

export default SidebarUserControl;
