import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { DateTime } from "luxon";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./DatePickerStyles.css";
import { rootStore } from '../stores/RootStore';

interface WhatsAppAnalyticsProps {
  title: string;
}

const WhatsAppAnalytics: React.FC<WhatsAppAnalyticsProps> = observer(({ title }) => {
  const { analyticsStore } = rootStore;
  const [startDate, setStartDate] = useState<Date>(
    DateTime.now().minus({ days: 7 }).startOf('day').toJSDate()
  );
  const [endDate, setEndDate] = useState<Date>(
    DateTime.now().endOf('day').toJSDate()
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        await analyticsStore.fetchAnalytics(startDate, endDate);
      } catch (error) {
        console.error("Failed to fetch analytics:", error);
      }
    };

    fetchData();
  }, [analyticsStore, startDate, endDate]);

  if (analyticsStore.isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <span className="loading loading-spinner loading-lg"></span>
      </div>
    );
  }

  const flowData = Object.entries(analyticsStore.messagesByFlow).map(([flowId, data]) => ({
    flowName: flowId,
    messageCount: data.messages,
    costs: data.costs,
  }));

  return (
    <div className="flex flex-col w-full max-w-7xl mx-auto p-4 gap-6">
      <div className="text-2xl font-bold">{title}</div>

      <div className="flex items-center w-full gap-4">
        <div className="relative flex items-center">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
              <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z"/>
            </svg>
          </div>
          <DatePicker
            selected={startDate}
            onChange={(date) => date && setStartDate(date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            className="input input-bordered pl-10"
            dateFormat="dd.MM.yyyy"
            placeholderText="Startdatum"
            wrapperClassName="w-full"
          />
        </div>
        <span className="mx-2">bis</span>
        <div className="relative flex items-center">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
              <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z"/>
            </svg>
          </div>
          <DatePicker
            selected={endDate}
            onChange={(date) => date && setEndDate(date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            className="input input-bordered pl-10"
            dateFormat="dd.MM.yyyy"
            placeholderText="Enddatum"
            wrapperClassName="w-full"
          />
        </div>
      </div>

      <div className="grid grid-cols-4 gap-4">
        <div className="col-span-3 card bg-base-100 border border-neutral">
          <div className="card-body">
            <h2 className="card-title">Nachrichten pro Tag</h2>
            <div className="h-64">
              <ResponsiveContainer width="100%" height="100%">
                <LineChart data={analyticsStore.dailyData}>
                  <CartesianGrid strokeDasharray="3 3" stroke="#374151" opacity={0.2} />
                  <XAxis
                    dataKey="date"
                    tickFormatter={(date: string) => DateTime.fromISO(date).toFormat("dd.MM")}
                    tick={{ fontSize: 11 }}
                    interval="preserveStartEnd"
                    minTickGap={40}
                  />
                  <YAxis tick={{ fontSize: 11 }} />
                  <Tooltip
                    formatter={(value: number) => [value, "Nachrichten"]}
                    labelFormatter={(date: string) => DateTime.fromISO(date).toFormat("dd.MM.yyyy")}
                    contentStyle={{
                      backgroundColor: "#1d1d1d",
                      border: "1px solid #374151",
                      borderRadius: "0.375rem",
                      fontSize: "11px",
                      padding: "0.5rem",
                      color: "white",
                    }}
                  />
                  <Line
                    type="monotone"
                    dataKey="messageCount"
                    stroke="#2563eb"
                    dot={false}
                    activeDot={{
                      fill: "#2563eb",
                      stroke: "white",
                      r: 6,
                      strokeWidth: 2,
                    }}
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>

        <div className="space-y-4">
          <div className="card bg-base-100 border border-neutral">
            <div className="card-body">
              <h3 className="text-sm font-medium opacity-70">Nachrichten Gesamt</h3>
              <p className="text-2xl font-bold">{analyticsStore.totalMessagesSent.toLocaleString()}</p>
            </div>
          </div>

          <div className="card bg-base-100 border border-neutral">
            <div className="card-body">
              <h3 className="text-sm font-medium opacity-70">Kosten Gesamt</h3>
              <p className="text-2xl font-bold">{analyticsStore.totalCosts.toFixed(2)}€</p>
            </div>
          </div>
        </div>
      </div>

      <div className="overflow-x-auto">
        <table className="table">
          <thead>
            <tr>
              <th>Flow</th>
              <th className="text-right">Nachrichten</th>
              <th className="text-right">Kosten</th>
            </tr>
          </thead>
          <tbody>
          {flowData.map((flow, index) => (
              <tr key={index}>
                <td>{flow.flowName}</td>
                <td className="text-right">{flow.messageCount.toLocaleString()}</td>
                <td className="text-right">{flow.costs.toFixed(2)}€</td>
              </tr>
            ))}
            <tr className="font-bold">
              <td>Gesamt</td>
              <td className="text-right">
                {flowData.reduce((sum, flow) => sum + flow.messageCount, 0).toLocaleString()}
              </td>
              <td className="text-right">
                {flowData.reduce((sum, flow) => sum + flow.costs, 0).toFixed(2)}€
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
});

export default WhatsAppAnalytics;