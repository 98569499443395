import { AnalyticsStore } from "./AnalyticsStore";
import { AuthStore } from "./AuthStore";
import { CustomerStore } from "./CustomerStore";
import { ToastStore } from "./ToastStore";

export class RootStore {
  authStore: AuthStore;
  toastStore: ToastStore;
  customerStore: CustomerStore;
  analyticsStore: AnalyticsStore;

  constructor() {
    this.authStore = new AuthStore(this);
    this.customerStore = new CustomerStore(this);
    this.toastStore = new ToastStore(this);
    this.analyticsStore = new AnalyticsStore(this);
  }
}

export const rootStore = new RootStore();
