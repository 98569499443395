import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import AppLayout from "./pages/AppLayout";
import { ThemeProvider } from "./contexts/ThemeContext";
import ProtectedRoute from "./pages/ProtectedRoute";
import ToastContainer from "./components/ToastContainer";
import AnalyticsDashboard from "./pages/WhatsAppAnalytics";
import WhatsAppAnalytics from "./pages/WhatsAppAnalytics";

const App: React.FC = () => {
  return (
    <ThemeProvider>
      <ToastContainer />
      <Router>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route element={<ProtectedRoute />}>
            <Route element={<AppLayout />}>
              <Route index element={<WhatsAppAnalytics title="Studio Mustermann"/>} />
              <Route path="/analytics" element={<WhatsAppAnalytics title="Studio Mustermann"/>} />
            </Route>
          </Route>
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default App;
