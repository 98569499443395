import { makeAutoObservable, runInAction } from "mobx";
import { apiService } from "../ApiService";
import { AnalyticsDailyResultDTO, AnalyticsSyncJob } from "../backend-types";
import { API_CONFIG } from "../config/api.config";
import { RootStore } from "./RootStore";

export class AnalyticsStore {
  results: AnalyticsDailyResultDTO[] = [];
  currentSyncJob: AnalyticsSyncJob | null = null;
  isLoading: boolean = false;
  error: string | null = null;

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this);
  }

  async fetchAnalytics(fromDate: Date, toDate: Date) {
    this.isLoading = true;
    this.error = null;
    
    try {
      const formattedFromDate = fromDate.toISOString().split('T')[0];
      const formattedToDate = toDate.toISOString().split('T')[0];
      
      const results = await apiService.get<AnalyticsDailyResultDTO[]>(
        `${API_CONFIG.ENDPOINTS.ANALYTICS_RESULTS}?fromDate=${formattedFromDate}&toDate=${formattedToDate}`
      );
      
      runInAction(() => {
        this.results = results;
        this.isLoading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.error = error instanceof Error ? error.message : 'An error occurred';
        this.isLoading = false;
      });
      throw error;
    }
  }

  async getSyncStatus(date: Date) {
    try {
      const formattedDate = date.toISOString().split('T')[0];
      const status = await apiService.get<AnalyticsSyncJob>(
        `${API_CONFIG.ENDPOINTS.ANALYTICS_SYNC_STATUS}/${formattedDate}`
      );
      
      runInAction(() => {
        this.currentSyncJob = status;
      });
      
      return status;
    } catch (error) {
      runInAction(() => {
        this.error = error instanceof Error ? error.message : 'An error occurred';
      });
      throw error;
    }
  }

  async triggerSync(date: Date) {
    try {
      const formattedDate = date.toISOString().split('T')[0];
      await apiService.post(
        `${API_CONFIG.ENDPOINTS.ANALYTICS_TRIGGER_SYNC}/${formattedDate}`,
        null
      );
      
      // Fetch the new sync status after triggering
      await this.getSyncStatus(date);
    } catch (error) {
      runInAction(() => {
        this.error = error instanceof Error ? error.message : 'An error occurred';
      });
      throw error;
    }
  }

  clear() {
    runInAction(() => {
      this.results = [];
      this.currentSyncJob = null;
      this.isLoading = false;
      this.error = null;
    });
  }

  // Computed values for analytics
  get totalMessagesSent() {
    return this.results.reduce((sum, result) => sum + result.totalMessagesSent, 0);
  }

  get totalCosts() {
    return this.results.reduce((sum, result) => sum + result.totalCosts, 0);
  }

  get totalEnrollments() {
    return this.results.reduce((sum, result) => sum + result.totalEnrollments, 0);
  }

  get messagesByFlow() {
    return this.results.reduce((acc, result) => {
      if (!acc[result.flowId]) {
        acc[result.flowId] = {
          messages: 0,
          costs: 0,
          studioName: result.studioName
        };
      }
      acc[result.flowId].messages += result.totalMessagesSent;
      acc[result.flowId].costs += result.totalCosts;
      return acc;
    }, {} as Record<string, { messages: number; costs: number; studioName: string }>);
  }

  get dailyData() {
    const dataMap = new Map<string, {
      date: string;
      messageCount: number;
      costs: number;
    }>();

    this.results.forEach(result => {
      const dateStr = result.date.toString().split('T')[0];
      const existing = dataMap.get(dateStr) || { date: dateStr, messageCount: 0, costs: 0 };
      
      dataMap.set(dateStr, {
        date: dateStr,
        messageCount: existing.messageCount + result.totalMessagesSent,
        costs: existing.costs + result.totalCosts
      });
    });

    return Array.from(dataMap.values())
      .sort((a, b) => a.date.localeCompare(b.date));
  }
}